import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue10({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="Second child, investments, new projects and more"
            pageSocialImage=""
            pageTitle="Issue #10"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #10"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "February was a month with big changes for me along with some reflection on what I’ve been doing and where I want to go.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at February"
                    content={[
                        {spans: [], text: "👨‍👩‍👧‍👧 Family of 4", type: "heading4"},
                        {spans: [], text: "February, was the month that had me in limbo for so long. My second daughter was born – she was 2 weeks early but my wife and I knew she was going to be early.", type: "paragraph"},
                        {spans: [], text: "Unlike with my first daughter, I was able to have a full 2 weeks off from work. With my first daughter, we couldn’t take the financial hit of being off work for 2 weeks with just statutory pay.", type: "paragraph"},
                        {spans: [], text: "This time around, I got paid nothing, but I earned a lot more from freelancing over the past 17 months and had saved a lot of it which meant having 2 weeks off was possible – and it was great!", type: "paragraph"},
                        {spans: [], text: "🏔 Reflection", type: "heading4"},
                        {spans: [], text: "When you’re busy all the time you don’t have the capacity to think things through properly.", type: "paragraph"},
                        {spans: [], text: "In a month I’ll have finished the contract I’ve been doing since September. The previous contract I had done prior, came back and offered to bring me on board again and I feel like my current one would like to extend too.", type: "paragraph"},
                        {spans: [], text: "But is that what I really want? I sat down with my wife and went through the pros and cons of doing contract work vs working directly with clients.", type: "paragraph"},
                        {spans: [], text: "I came to the conclusion that at the very least I need a break from contracting. This means I’ll try my best to get back into working directly with clients and see how that goes.", type: "paragraph"},
                        {spans: [{
                            start: 112,
                            end: 142,
                            type: "hyperlink",
                            data: {
                                link_type: "Document",
                                uid: "articles/reflection-on-making-£100k-freelancing",
                            },
                        }], text: "I also hit a key financial milestone this month – I’ve invoiced £100k since I started freelancing and I wrote a short article reflecting on it.", type: "paragraph"},
                        {spans: [], text: "👷‍♂️ New work", type: "heading4"},
                        {spans: [], text: "I’ve had a lot of new work enquiries this month, it felt like every day I was telling my wife about a new project that had come in (over the course of like 2 weeks).", type: "paragraph"},
                        {spans: [], text: "One of the projects that came in was from a friend of mine with who I went to university. He’s doing contract work with a FinTech startup (around sustainable investing) and they need someone to build a new marketing site for them.", type: "paragraph"},
                        {spans: [], text: "I’ve sent some quotes off for some others, waiting on replies from others and scheduling in calls for others.", type: "paragraph"},
                        {spans: [], text: "💸 Investments", type: "heading4"},
                        {spans: [{
                            start: 47,
                            end: 54,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://ecologi.com/",
                            },
                        }], text: "November last year, I invested some money into Ecologi, you may have seen me mention them in previous newsletters. As well as being a customer I now own shares in the company.", type: "paragraph"},
                        {spans: [], text: "This month I decided to invest some more money into 2 other companies.", type: "paragraph"},
                        {spans: [{
                            start: 174,
                            end: 186,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://www.stemandglory.uk/",
                            },
                        }], text: "One is a plant-based restaurant that is looking to roll out a new restaurant and their own ready meals (they already have 2 successful restaurants in Cambridge and London) – Stem & Glory.", type: "paragraph"},
                        {spans: [{
                            start: 134,
                            end: 142,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://thestem.co.uk/",
                            },
                        }], text: "The other is an online house plant store having just expanded to nationwide service, but have their values rooted in sustainability – The Stem.", type: "paragraph"},
                        {spans: [], text: "What I look for when making these decisions is whether their values align with my own – either I’m already a customer or could see myself being one. Ultimately I want to put my money into things I believe in.", type: "paragraph"},
                        {spans: [], text: "☘️ Updates to personal site", type: "heading4"},
                        {spans: [{
                            start: 117,
                            end: 132,
                            type: "hyperlink",
                            data: {
                                link_type: "Document",
                                uid: "newsletter",
                            },
                        }], text: "I made a few amends to my personal site (mostly small improvements), but one of the bigger changes I added was to my newsletter page.", type: "paragraph"},
                        {spans: [], text: "All previous newsletters can now be found on my personal site, which means all of this content isn’t just lost at the end of the month.", type: "paragraph"},
                        {spans: [], text: "👨‍🍳 Updates to Stryve", type: "heading4"},
                        {spans: [{
                            start: 33,
                            end: 39,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://stryve.life/",
                            },
                        }], text: "I’ve made quite a few updates to Stryve this month. I’ve updated the homepage so it feels a little more curated, pulling through the latest recipes and articles.", type: "paragraph"},
                        {spans: [], text: "I also implemented cooking mode on desktop (which is available on all recipes). I added another 5 to 6 recipes this month too so the site is starting to feel a bit fuller and I wrote a draft for a new blog article.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 February goals"
                    checkList={[
                        {complete: true, text: "👟 Continue my walking habit (even after having my second daughter)"},
                        {complete: false, text: "💵 Keep total monthly spend below £3k"},
                        {complete: true, text: "📙 Finish 2 books"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to March"
                    content={[
                        {spans: [], text: "March is going to be a busy month.", type: "paragraph"},
                        {spans: [], text: "I’ll be finishing off my last weeks on my contract project and start building the new FinTech apps marketing site.", type: "paragraph"},
                        {spans: [], text: "Other than this I will probably be doing some design work for a Crypto project and some dev work for an ongoing client.", type: "paragraph"},
                        {spans: [], text: "I’d like to fit some time into Stryve but I don’t imagine I’ll have much time for it other than adding some new recipes.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 March goals"
                    checkList={[
                        {complete: false, text: "💵 Keep total monthly spend below £3k"},
                        {complete: false, text: "💳 Persuade my wife to move over to Monzo joint account"},
                        {complete: false, text: "🧭 Create a content plan for Stryve"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="Do a good job. Over the past 17 months, it amazes me how many bad experiences clients and agencies have had with other freelancers. The bar to entry is pretty low if you’re decent at what you do."
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/d224323f-6ded-431d-9a73-105af9b56feb_income-feb-2022.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £3.1k", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/e3cc858f-4b27-41a9-a61b-6f3fc4fce0ff_bellroy-classic-premium-backpack.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Bellroy classic backpack", type: "heading4"},
                        {spans: [], text: "I bought this bag for myself in the black Friday sale – I had been eying it up for a month or so prior waiting to see if it’d get discounted.", type: "paragraph"},
                        {spans: [], text: "I chose it for a couple of reasons, one being that it’s made from recycled bottles and environmentally certified leather. Two, I knew Bellroy products were good because I’ve been using a wallet by them since 2017 (maybe even longer) and it’s still going strong.", type: "paragraph"},
                        {spans: [], text: "The bag is great, it has lots of different compartments to store things, most useful to me is the laptop store.", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://bellroy.com/products/classic-backpack-premium/baida_nylon/lichen#slide-0",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/84cafbfe-a01a-4d12-94c6-dd258d0d69e6_your-money-or-your-life.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Your money or your life", type: "heading4"},
                        {spans: [], text: "This book was very fitting this month, it’s more about life than money. Why do we all spend so much of our time working for money? Once you hit a certain point, do you really need more? Could you be better off if you were more conscious about your spending?", type: "paragraph"},
                        {spans: [], text: "It can be hard to say no to more work to earn more, but what could you be doing with your time that is more fulfilling if you didn’t do that extra project? These are all questions for myself.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.amazon.co.uk/Your-Money-or-Life/dp/B07DLDSR1R/",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue10;
